import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Web accessibility has many facets: Operation with the keyboard and other input devices, alternative
texts for images, descriptive headings and error texts, etc. The range of tools for testing the
accessibility of websites is just as diverse.`}</p>
    <p>{`First things first: there is no `}<ItalicText mdxType="ItalicText">{`single power tool`}</ItalicText>{` that automatically detects
all barriers and ideally also removes them straight away. This also applies to AI-based accessibility
tools. Yes, artificial intelligence has great potential, but
`}<a parentName="p" {...{
        "href": "https://adrianroselli.com/2023/06/no-ai-will-not-fix-accessibility.html"
      }}>{`current tools cannot replace a comprehensive accessibility test`}</a>{`.
Even if certain people claim otherwise in the frenzy of `}<a parentName="p" {...{
        "href": "https://www.forbes.com/sites/christianstadler/2024/09/06/the-generative-ai-hype-is-almost-over-whats-next/"
      }}>{`AI hype`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBP/EABYBAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABpDunlRof/8QAGRAAAgMBAAAAAAAAAAAAAAAAAhEAAQMS/9oACAEBAAEFAh16rTQxGuUlOain/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERIRASMkH/2gAIAQEABj8C5IjZv0VSys//xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhMVFh/9oACAEBAAE/IdfrwZUdGQuDpZmKv4QmIaEH/9oADAMBAAIAAwAAABDrP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQMBAT8QDtpf/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8Qqv/EABwQAQEAAgIDAAAAAAAAAAAAAAERAFExYSFBgf/aAAgBAQABPxBIX3PPZgCBaSiENmRbB5ZT6wjAJIaxKyn3ADjP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Various garden tools and plants on a table.",
          "title": "Various garden tools and plants on a table.",
          "src": "/static/7dbd04e7d2902ac8918de4e2a092e3c1/e5166/pexels-thirdman-garden-tools.jpg",
          "srcSet": ["/static/7dbd04e7d2902ac8918de4e2a092e3c1/f93b5/pexels-thirdman-garden-tools.jpg 300w", "/static/7dbd04e7d2902ac8918de4e2a092e3c1/b4294/pexels-thirdman-garden-tools.jpg 600w", "/static/7dbd04e7d2902ac8918de4e2a092e3c1/e5166/pexels-thirdman-garden-tools.jpg 1200w", "/static/7dbd04e7d2902ac8918de4e2a092e3c1/b17f8/pexels-thirdman-garden-tools.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Thirdman / pexels.com`}</em></p>
    <p>{`We need the right tools for the delicate little plant of accessibility to flourish. Here is my personal
selection of free bookmarklets, browser extensions and accessibility testing tools.`}</p>
    <h2>{`Structure and Semantics`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wave.webaim.org/"
        }}>{`WAVE (Web Accessibility Evaluation Tools)`}</a>{`: An accessibility analysis tool
from WebAIM. Available as a browser extension for Firefox, Chrome and Edge. I find the structure view
particularly useful, where you can see at a glance whether meaningful landmarks
(`}<InlineCode mdxType="InlineCode">{`header`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`main`}</InlineCode>{`, etc.) and heading levels have been defined.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://firefox-source-docs.mozilla.org/devtools-user/accessibility_inspector/"
        }}>{`Firefox Accessibility Inspector`}</a>{`
is a built-in component of the Firefox developer tools. Do you want to know what information an interactive
element communicates to assistive technologies? Simply right-click on it and
select `}<ItalicText mdxType="ItalicText">{`“Inspect Accessibility Properties”`}</ItalicText>{`. Firefox opens the “Accessibility” tab
in the developer tools and jumps to the corresponding node in the
`}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Glossary/Accessibility_tree"
        }}>{`accessibility tree`}</a>{`.
Debugging has never been so easy! 😍`}</li>
      <li parentName="ul">{`Test with `}<BoldText mdxType="BoldText">{`screen readers`}</BoldText>{`: No accessibility audit is complete without screen
reader testing. In view of limited budgets in projects, it is not possible to test with
`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/List_of_screen_readers"
        }}>{`all screen readers on all platforms`}</a>{`. Based on
`}<a parentName="li" {...{
          "href": "https://webaim.org/projects/screenreadersurvey10/"
        }}>{`current statistics`}</a>{`, I have therefore defined the
following test scenarios:`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.nvaccess.org/"
            }}>{`NVDA`}</a>{` in Windows`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.apple.com/accessibility/vision/"
            }}>{`VoiceOver`}</a>{` in iOS`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://support.google.com/accessibility/android/answer/6283677?hl=en"
            }}>{`TalkBack`}</a>{` in Android`}</li>
        </ul>
      </li>
      <li parentName="ul">{`If you have never tested with the screen reader, then read my article
`}<a parentName="li" {...{
          "href": "/a11y-audits-screenreader.en/"
        }}>{`“5 Tips for getting started with Accessibility Audits using a screen reader”`}</a>{`.`}</li>
    </ul>
    <p>{`Ideal for testing the WCAG criteria `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/info-and-relationships.html"
      }}>{`1.3.1 Info and Relationships`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/name-role-value.html"
      }}>{`4.1.2 Name, Role, Value`}</a>{`.`}</p>
    <h2>{`Keyboard Operability`}</h2>
    <ul>
      <li parentName="ul">{`Test with `}<BoldText mdxType="BoldText">{`keyboard`}</BoldText>{`: Use the tab key to access the interactive elements on the page
(buttons, links, etc.). Does the focus order make sense? Can keyboard users reach and activate all
interactive elements? Is the focused element visibly highlighted? On a smartphone or tablet, you can simply
connect a Bluetooth keyboard for testing.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chromewebstore.google.com/detail/taba11y-tab-order-accessi/aocppmckdocdjkphmofnklcjhdidgmga"
        }}>{`taba11y`}</a>{`:
This useful browser extension calculates the tab order of all elements and displays it visually, either
by drawing a path or by highlighting the elements.`}</li>
    </ul>
    <p>{`Ideal for testing the WCAG criteria `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/keyboard.html"
      }}>{`2.1.1 Keyboard`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/no-keyboard-trap"
      }}>{`2.1.2 No Keyboard Trap`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-order"
      }}>{`2.4.3 Focus Order`}</a>{`, and
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-visible"
      }}>{`2.4.7 Focus Visible`}</a>{`.`}</p>
    <h2>{`Responsive Content`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.microsoft.com/en-us/edge/learning-center/how-to-change-font-size?form=MA13I2"
        }}>{`Browser settings`}</a>{`:
Change the default font size of your browser to check whether the website automatically adjusts the text
size. Most browsers, such as Edge, only allow you to choose from predefined options such as “Small”,
“Medium” and “Large”. In `}<a parentName="li" {...{
          "href": "https://support.mozilla.org/en-US/kb/change-fonts-and-colors-websites-use"
        }}>{`Firefox`}</a>{`,
on the other hand, users can define a specific pixel value as the default font size.`}</li>
      <li parentName="ul">{`Alternatively, you can use the browser's zoom function (`}<InlineCode mdxType="InlineCode">{`CTRL`}</InlineCode>{`
and `}<InlineCode mdxType="InlineCode">{`+`}</InlineCode>{` key) to enlarge the entire page content in proportion to the font size.`}</li>
      <li parentName="ul">{`Use the `}<a parentName="li" {...{
          "href": "https://codepen.io/stevef/full/YLMqbo"
        }}>{`text spacing bookmarklet`}</a>{` or a browser extension (e.g.
`}<a parentName="li" {...{
          "href": "https://chromewebstore.google.com/detail/editor-for-textlayout/amnelgbfbdlfjeaobejkfmjjnmeddaoj"
        }}>{`Editor for Textlayout`}</a>{`)
to set the values for line height, spacing following paragraphs, letter spacing and word spacing to the
maximum required values. No content should be cut off or overlap.`}</li>
    </ul>
    <p>{`Ideal for testing the WCAG criteria `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/resize-text.html"
      }}>{`1.4.4 Resize Text`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/reflow"
      }}>{`1.4.10 Reflow`}</a>{`,
and `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/text-spacing"
      }}>{`1.4.12 Text Spacing`}</a>{`.`}</p>
    <h2>{`Target Size of Interactive Elements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://html5accessibility.com/stuff/2023/08/28/quick-and-very-dirty-target-size-checker/"
        }}>{`Target Size Bookmarklet`}</a>{`:
It identifies interactive elements, calculates their center and draws a semi-transparent circle of 24 by 24
pixels around the center of the element: A green circle with a thick border means that the target size is
sufficient. Elements with a blue circle should be examined manually.`}</li>
      <li parentName="ul">{`This way, you can find buttons and links much more quickly where users with motor impairments may have
problems clicking or tapping.`}</li>
    </ul>
    <p>{`Ideal for testing the WCAG criterion `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/target-size-minimum"
      }}>{`2.5.8 Target Size (Minimum)`}</a>{`.`}</p>
    <h2>{`Minimum Contrast`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.chrome.com/docs/lighthouse/overview"
        }}>{`Chrome Lighthouse`}</a>{` is part of the developer
tools in Chrome. The Lighthouse accessibility check reliably finds the majority of elements with too
low a contrast ratio.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://learn.microsoft.com/en-us/windows/powertoys/#color-picker"
        }}>{`PowerToys Color Picker`}</a>{`:
The Microsoft PowerToys are a collection of utilities for power users. With the key
combination `}<InlineCode mdxType="InlineCode">{`WIN`}</InlineCode>{`+`}<InlineCode mdxType="InlineCode">{`Shift`}</InlineCode>{`+`}<InlineCode mdxType="InlineCode">{`C`}</InlineCode>{`
you can activate the color picker at any time and extract the color value of a pixel on the screen.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://webaim.org/resources/contrastchecker/"
        }}>{`WebAIM Contrast Checker`}</a>{`: Simple tool to check the
contrast ratio of a font color to the background color. A particularly useful feature is that you can
share the selected color values and their evaluation as a permalink.`}</li>
    </ul>
    <p>{`Ideal for testing the WCAG criteria `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/contrast-minimum.html"
      }}>{`1.4.3 Contrast (Minimum)`}</a>{`
und `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/non-text-contrast"
      }}>{`1.4.11 Non-text Contrast`}</a>{`.`}</p>
    <h2>{`Additional Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/test-evaluate/tools/list/"
        }}>{`Web Accessibility Evaluation Tools List  (W3C)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://accessibility.huit.harvard.edu/testing"
        }}>{`Accessibility Testing Tools and Practices (Harvard University)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      